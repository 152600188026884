import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Avatar,
  AvatarGroup as MuiAvatarGroup,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Typography as MuiTypography,
  FormControl as MuiFormControl,
  Paper as MuiPaper,
  MenuItem,
  IconButton,
  CardMedia as MuiCardMedia,
  CardActions,
  Chip as MuiChip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { spacing, SpacingProps } from "@mui/system";
import { green, pink, deepOrange, deepPurple } from "@mui/material/colors";
import {
  useGetDriveItemChildrenQuery,
  useGetDriveItemsQuery,
  useGetDriveQuery,
  useGetSiteQuery,
  useGetTeamChannelFilesFolderQuery,
  useGetTeamChannelsQuery,
  useGetTeamQuery,
} from "../../../redux/slices/graphApiSlice";
import {
  useDeleteSharePointConnectionMutation,
  useGetSharePointConnectionsQuery,
  useGetUserSettingsQuery,
} from "../../../redux/slices/indexApiSlice";
import { useTranslation } from "react-i18next";
import SharePointConnectionDialog from "../../../components/dialogs/SharePointConnectionDialog";
import { DialogMode } from "../../../types/dialogmode";
import { ISharePointConnection } from "../../../types/sharepointconnection";
import StyledMenu from "../../../theme/StyledMenu";
import MenuItemAsLabel from "../../../theme/MenuItemAsLabel";
import SharePointFilesDialog from "../../../components/dialogs/SharePointFilesDialog";
import AnalyzingContractsLoader from "../../../components/AnalyzingContractsLoader";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { IMSTeamsChannel } from "../../../types/teamsChannel";

const Paper = styled(MuiPaper)(spacing);

const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)<{ m?: number }>`
  min-width: 148px;
`;

const PinkAvatar = styled(Avatar)`
  background-color: ${pink[500]};
`;

const GreenAvatar = styled(Avatar)`
  background-color: ${green[500]};
`;

const OrangeAvatar = styled(Avatar)`
  background-color: ${deepOrange[500]};
`;

const PurpleAvatar = styled(Avatar)`
  background-color: ${deepPurple[500]};
`;

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)`
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
`;

const Chip = styled(MuiChip)<{ color?: string }>`
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};
`;

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

interface TypographyProps extends SpacingProps {
  component?: string;
}
const Typography = styled(MuiTypography)<TypographyProps>(spacing);

const Spacer = styled.div(spacing);

interface SharePointConnectionProps {
  connection: ISharePointConnection;
}

const CardMedia = styled(MuiCardMedia)`
  // height: auto; // 220px
`;

const MSTeamsChannelCard = ({
  team,
  channel,
}: {
  team: any;
  channel: IMSTeamsChannel;
}) => {
  const { data: folder } = useGetTeamChannelFilesFolderQuery({
    ...channel,
    teamId: team.id,
  });

  const { data: driveItems } = useGetDriveItemChildrenQuery({
    driveId: folder?.parentReference?.driveId,
    driveItemId: folder?.id,
  });

  return (
    <Card>
      {/* {image ? <CardMedia image={image} title="Contemplative Reptile" /> : null} */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {team?.displayName}
        </Typography>

        <Typography mb={4} color="textSecondary" component="p">
          {channel?.displayName}
        </Typography>

        <Typography mb={4} color="textSecondary" component="p">
          {channel?.webUrl}
        </Typography>
      </CardContent>
      <CardActions>
        {driveItems && <SharePointFilesDialog driveItems={driveItems} />}
      </CardActions>
    </Card>
  );
};

const MSTeamsCard = ({ teamsId }: { teamsId: string }) => {
  const { data: team } = useGetTeamQuery(teamsId ?? skipToken);
  const { data: channels = [] } = useGetTeamChannelsQuery(teamsId ?? skipToken);

  return (
    <div>
      {team && (
        <div>
          {channels?.map((channel) => (
            <MSTeamsChannelCard team={team} channel={channel} />
          ))}
        </div>
      )}
    </div>
  );
};

const SharePointConnectionCard = ({ ...props }: SharePointConnectionProps) => {
  const { t } = useTranslation();

  const connection = props.connection;

  const [initials, setInitials] = useState("");
  const { data: site } = useGetSiteQuery(connection.siteId);
  const { data: drive } = useGetDriveQuery(connection.driveId);
  const { data: driveItems, isLoading } = useGetDriveItemsQuery(
    connection.driveId ?? {
      skiptoken: !connection.driveId,
    }
  );
  const [
    deleteConnection,
    { isLoading: isDeleting, isSuccess: isDeleted, isError: errorOccurred },
  ] = useDeleteSharePointConnectionMutation();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.connection?.id) {
      deleteConnection(props.connection.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();
    // Hide the confirmation
    setShowConfirmation(false);

    // Close the menu
    handleClose();
  };

  useEffect(() => {
    if (connection.siteId && connection.driveId) {
      console.log("driveItems", driveItems);
    }
  }, [connection.siteId, connection.driveId, driveItems]);

  useEffect(() => {
    if (site?.displayName) {
      // get the first letter of each word in the title
      const initials = site?.displayName
        ?.split(" ")
        .map((word: string) => word.charAt(0))
        .join("");

      // take only the first 2 letters
      const initialsShort = initials?.substring(0, 2);

      setInitials(initialsShort);
    }
  }, [site]);

  const chip = <Chip label="Finished" color="success" />;

  return (
    <Card
      style={{
        filter:
          (isDeleting || isDeleted) && !errorOccurred ? "blur(3px)" : "none",
        opacity: (isDeleting || isDeleted) && !errorOccurred ? "0.5" : "1",
        transition: "filter 0.8s ease-in-out, opacity 0.8s ease-in-out",
      }}
    >
      {/* {image ? <CardMedia image={image} title="Contemplative Reptile" /> : null} */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {site?.displayName}
        </Typography>

        <Typography mb={4} color="textSecondary" component="p">
          {drive?.name}
        </Typography>

        <Typography mb={4} color="textSecondary" component="p">
          {site?.webUrl}
        </Typography>
      </CardContent>
      <CardActions>
        {driveItems && <SharePointFilesDialog driveItems={driveItems} />}

        <IconButton
          aria-label="more"
          id={`connection-menubutton-${connection?.id}`}
          aria-controls={open ? `connection-menu-${connection?.id}` : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          sx={{ ":hover": { visibility: "visible" } }}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          id={`message-menu-${connection?.id}`}
          MenuListProps={{
            "aria-labelledby": `connection-menubutton-${connection?.id}`,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem disableRipple>
            <EditIcon />
            {t("Edit")}
          </MenuItem>

          <Divider sx={{ my: 0.5 }} />

          <MenuItem onClick={() => setShowConfirmation(true)} disableRipple>
            <DeleteIcon />
            {t("Delete")}
          </MenuItem>

          {showConfirmation && (
            <>
              <MenuItemAsLabel sx={{ justifyContent: "center" }}>
                Are you sure?
              </MenuItemAsLabel>

              {/* <Fade in={showConfirmation}> */}
              <MenuItemAsLabel>
                <Button
                  color="primary"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  Yes, delete!
                </Button>
                <Button
                  color="primary"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  Cancel
                </Button>
              </MenuItemAsLabel>
              {/* </Fade> */}
            </>
          )}
        </StyledMenu>
      </CardActions>
    </Card>
  );
};

function SharePointConnections() {
  const { t } = useTranslation();

  const { data: connections } = useGetSharePointConnectionsQuery();
  const { data: userSettings } = useGetUserSettingsQuery();

  useEffect(() => {
    if (userSettings && userSettings?.microsoftTeamsIds?.length > 0) {
      console.log("userSettings", userSettings.microsoftTeamsIds);
    }
  }, [userSettings]);

  return (
    <React.Fragment>
      <Helmet title={t("SharePoint Connections")!} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("SharePoint Connections")}
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid>
          <AnalyzingContractsLoader />
        </Grid>

        <Grid>
          <SharePointConnectionDialog mode={DialogMode.Add} />
        </Grid>
        <Grid>
          <Grid container spacing={6}>
            {connections?.map(
              (connection: ISharePointConnection, index: number) => (
                <Grid
                  key={`connection-${connection.id}`}
                  size={{ xs: 12, md: 4 }}
                >
                  <SharePointConnectionCard connection={connection} />
                </Grid>
              )
            )}

            {userSettings?.microsoftTeamsIds?.map((teamsId) => (
              <Grid key={`teamsId-${teamsId}`} size={{ xs: 12, md: 4 }}>
                <MSTeamsCard teamsId={teamsId} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default SharePointConnections;

import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  useTheme,
  Typography,
  useMediaQuery,
  Divider as MuiDivider,
  Tabs,
  Tab,
  Card,
  Paper,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  BadgeProps,
  CardContent,
  Chip,
  Alert,
  Tooltip,
  linearProgressClasses,
  Avatar,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AIContractSigneeManagement from "./AIContractSigneeManagement";
import { IDocument } from "../../../../types/document";
import { DialogMode } from "../../../../types/dialogmode";
import { ISignature, ISignee } from "../../../../types/signature";
import {
  AIReadingStatus,
  ContractStatus,
  IContract,
} from "../../../../types/contract";
import { v4 as uuidv4 } from "uuid";

import ContractAttachments from "./ContractAttachments";
import ContractNotes from "./ContractNotes";
import useAuth from "../../../../hooks/useAuth";
import SidebarRiskAnalysis from "./SidebarRiskAnalysis";
import AskMeAnything from "../../../components/AskMeAnything";
import SidebarRoles from "./SidebarRoles";
import {
  AttachFile,
  AutoAwesome,
  Block,
  Cancel,
  Check,
  Checklist,
  Class,
  Close,
  Error,
  Euro,
  Event,
  FolderCopy,
  Gavel,
  Groups,
  HistoryEdu,
  Info,
  ManageAccounts,
  MenuBook,
  Money,
  PanTool,
  Person,
  Public,
  QuestionMark,
  Settings,
  Store,
  Style,
  Subject,
  TaskAlt,
  TipsAndUpdates,
  Warning,
  WarningAmber,
} from "@mui/icons-material";
import OwlAvatarIcon from "../../../../icons/OwlAvatarIcon";
import ContractSummary from "./ContractSummary";
import ContractParties from "./ContractParties";
import ContractContactPersons from "./ContractContactPersons";
import ContractFinancials, { FinancialsCard } from "./ContractFinancials";
import { DollarSign } from "react-feather";
import ContractJurisdiction from "./ContractJurisdiction";
import ContractNoticePeriod, { NoticePeriodCard } from "./ContractNoticePeriod";
import ContractDates, { ContractDatesCard } from "./ContractDates";
import ContractCategory from "./ContractCategory";
import ContractType from "./ContractType";
import ContractSubject from "./ContractSubject";
import {
  useAddHumanDataVerificationMutation,
  useGetHumanDataVerificationsQuery,
  useGetPromptsQuery,
  useGetTermsAndConditionsDocumentQuery,
} from "../../../../redux/slices/indexApiSlice";
import { IPrompt } from "../../../../types/prompt";
import ContractFolders from "./ContractFolders";
import {
  ContractExtractionResult,
  Paragraph,
} from "../../../../types/aiOutput";
import ContractPromptAccordion from "./ContractPromptAccordion";
import PromptHeader from "../../dashboards/Analytics/PromptHeader";
import { HumanDataVerification } from "../../../../types/humanDataVerification";
import ContractSuggestedPromptAccordion from "./ContractSuggestedPromptAccordion";
import { LinearProgressWithLabel } from "../../dashboards/Analytics/PromptsSideBar";
import { red } from "@mui/material/colors";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import ArticleAccordion from "./ArticleAccordion";

const Divider = styled(MuiDivider)(spacing);

// create an enum for the tabs
export enum SideBarTabIndex {
  BASIC_DETAILS,
  // SCORES,
  SCORES_V2,
  FIELDS_FOUND,
  FIELDS_NOT_FOUND,
  FIELDS_EXCLUDED,
  FIELDS_SUGGESTED,
  CONTRACT_MANAGEMENT,
  AI_ASSISTANT,
}

export const ScoreBar = styled(LinearProgressWithLabel)(({ theme }) => ({
  height: 14,
  borderRadius: 7,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "transparent",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    backgroundColor: red[600],
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 11,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
type AIContractDetailSidebarProps = {
  mode: DialogMode;
  signer?: ISignee;
  signees?: ISignee[];
  contract?: IContract;
  documents: IDocument[];
  document: IDocument;
  aiOutputs: ContractExtractionResult[];
  analysisStatus?: AIReadingStatus;
  onAnalysis?: () => void;
  setSignees?: React.Dispatch<React.SetStateAction<Array<ISignee>>>;
  setSignatures?: React.Dispatch<React.SetStateAction<Array<ISignature>>>;
  signatures?: ISignature[];
  resetSignatures?: React.Dispatch<React.SetStateAction<boolean>>;
  refetchContract?: () => void;
  setSearchForText?: (text: string) => void;
  promptIdToExpand?: string;
  handlePromptClick?: (prompt?: IPrompt) => void;
  setAiOutput?: React.Dispatch<
    React.SetStateAction<ContractExtractionResult | undefined>
  >;
  setSelectedParagraph: React.Dispatch<
    React.SetStateAction<Paragraph | undefined>
  >;
  sideBarTab: SideBarTabIndex;
  setSideBarTab: React.Dispatch<React.SetStateAction<SideBarTabIndex>>;
  switchToDocument: (documentId: string) => void;
};

export const DocumentChip = ({ ...props }: { document?: IDocument }) => {
  const { document } = props;

  return (
    <Chip
      label={document?.name}
      icon={document?.type === "attachment" ? <AttachFile /> : <Gavel />}
      variant="outlined"
      color="primary"
    />
  );
};

const PromptsSection = ({
  contract,
  prompts,
  mode,
  aiOutputs,
  selectParagraph,
  expanded,
  infoFound,
  verifications,
  documents,
}: {
  contract: IContract;
  prompts: IPrompt[];
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  selectParagraph: (
    paragraph?: Paragraph,
    prompt?: IPrompt,
    aiOutput?: ContractExtractionResult
  ) => void;
  expanded?: string;
  infoFound: boolean;
  verifications?: HumanDataVerification[];
  documents: IDocument[];
}) => {
  const { t } = useTranslation();

  const [promptsCorrectCount, setPromptsCorrectCount] = useState<number>(0);
  const [promptsIncorrectCount, setPromptsIncorrectCount] = useState<number>(0);
  const [promptsUnknownCount, setPromptsUnknownCount] = useState<number>(0);

  useEffect(() => {
    if (verifications && verifications?.length > 0) {
      // check if the latest aiOutput has a result for this prompt

      // for each prompt, check if there is a verification
      const verifiedPrompts = prompts.map((prompt) => {
        const promptResults = aiOutputs.filter((a) =>
          a.prompts?.some((p) => p.promptID === prompt.id)
        );

        const latestPromptResult = promptResults[0];

        const verification = verifications.find(
          (v) =>
            latestPromptResult &&
            v.aiOutputId === latestPromptResult.id &&
            v.field === prompt.id
        );

        return {
          ...prompt,
          isCorrect: verification?.isCorrect,
        };
      });

      // calculate the score
      const total = prompts.length;
      const correct = verifiedPrompts.filter((p) => p.isCorrect).length;
      const inCorrect = verifiedPrompts.filter(
        (p) => p.isCorrect === false
      ).length;
      const unknown = verifiedPrompts.filter((p) => p.isCorrect === undefined);

      setPromptsCorrectCount(correct);
      setPromptsIncorrectCount(inCorrect);
      setPromptsUnknownCount(unknown.length);
    }
  }, [verifications]);

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {/* <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Fields found")}
            </Typography> */}

      {prompts?.length > 0 && (
        <PromptHeader
          amount={promptsCorrectCount}
          totalAmount={prompts.length}
          title={t("Correct")!}
          description={
            <div>
              {t("Incorrect")}: {promptsIncorrectCount}
              <br />
              {t("Unknown")}: {promptsUnknownCount}
              <br />
            </div>
          }
        />
      )}

      <Box>
        {prompts.map((prompt) => {
          return (
            <ContractPromptAccordion
              key={`contractPromptAccordion-${prompt.id}`}
              prompt={prompt}
              contract={contract}
              mode={mode}
              aiOutputs={aiOutputs}
              selectParagraph={selectParagraph}
              isExpanded={expanded === prompt.id}
              infoFound={infoFound}
              documents={documents}
              // attachments={attachments}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const ScoresSection = ({
  contract,
  aiOutputs,
}: {
  contract: IContract;
  aiOutputs: ContractExtractionResult[];
}) => {
  const { t } = useTranslation();

  const [max, setMax] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [latestOutput, setLatestOutput] = useState<ContractExtractionResult>();

  useEffect(() => {
    const latestOutput = aiOutputs.filter(
      (a) => a.scores && a.scores.scores?.length > 0
    );

    if (latestOutput.length > 0) {
      setLatestOutput(latestOutput[0]);
    }
  }, [aiOutputs]);

  useEffect(() => {
    if (latestOutput?.scores?.scores) {
      const newMax = latestOutput.scores.scores.length * 10;
      setMax(newMax);

      const newTotal = latestOutput.scores.scores.reduce(
        (acc, curr) => acc + curr.score,
        0
      );
      setTotal(newTotal);
    }
  }, [latestOutput]);

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <Card
        // backgroundColor={backgroundColor}
        sx={{ width: "100%", display: "flex" }}
      >
        <CardContent
          sx={{
            color: "inherit",
            width: "100%",
          }}
        >
          <Grid container direction="column" gap={2}>
            {latestOutput?.scores?.scores.map((score) => (
              <Grid
                container
                direction="row"
                gap={5}
                size={12}
                flexWrap="nowrap"
                alignItems="center"
              >
                <Grid size={6}>
                  <Typography variant="body1" textAlign="right">
                    {score.artikelNummer} {score.artikelTitel}
                  </Typography>

                  {/* <Typography variant="caption">{score.explanation}</Typography> */}
                </Grid>

                <Grid size={6}>
                  <Tooltip title={<Typography>{score.explanation}</Typography>}>
                    <div>
                      <ScoreBar
                        variant="determinate"
                        value={(score.score / 10) * 100}
                        hideLabel
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Alert
            sx={{ mt: 5 }}
            variant="outlined"
            icon={<OwlAvatarIcon />}
            severity="info"
          >
            <Typography variant="body1">
              {latestOutput?.scores?.explanation}
            </Typography>
          </Alert>
        </CardContent>
      </Card>
    </Box>
  );
};

const ScoresV2Section = ({
  contract,
  aiOutputs,
}: {
  contract: IContract;
  aiOutputs: ContractExtractionResult[];
}) => {
  const { t } = useTranslation();

  const [max, setMax] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [riskLabel, setRiskLabel] = useState<string>("");

  const [latestOutput, setLatestOutput] = useState<ContractExtractionResult>();

  const { data: document } = useGetTermsAndConditionsDocumentQuery(
    latestOutput?.termsAndConditions?.documentId ?? skipToken
  );

  useEffect(() => {
    const latestOutput = aiOutputs.filter(
      (a) => a.termsAndConditions && a.termsAndConditions.scores?.length > 0
    );

    if (latestOutput.length > 0) {
      setLatestOutput(latestOutput[0]);
    }
  }, [aiOutputs]);

  useEffect(() => {
    if (latestOutput?.termsAndConditions) {
      const newMax = latestOutput.termsAndConditions?.scores.length * 10;
      setMax(newMax);

      const newTotal = latestOutput.termsAndConditions.scores?.reduce(
        (acc, curr) => acc + curr.score,
        0
      );
      setTotal(newTotal);

      const perc = (newTotal / newMax) * 100;
      if (perc > 90) {
        setRiskLabel("Very high risk");
      } else if (perc > 70) {
        setRiskLabel("High risk");
      } else if (perc > 30) {
        setRiskLabel("Medium risk");
      } else if (perc > 10) {
        setRiskLabel("Low risk");
      } else {
        setRiskLabel("Very low risk");
      }
    }
  }, [latestOutput]);

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {/* <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Fields found")}
            </Typography> */}

      {latestOutput?.termsAndConditions &&
        latestOutput?.termsAndConditions.scores?.length > 0 &&
        document && (
          <Card
            // backgroundColor={backgroundColor}
            sx={{ width: "100%", display: "flex" }}
          >
            <CardContent
              sx={{
                color: "inherit",
                width: "100%",
              }}
            >
              {riskLabel === "Very high risk" ? (
                <Error />
              ) : riskLabel === "High risk" ? (
                <Warning />
              ) : riskLabel === "Medium risk" ? (
                <Info />
              ) : (
                <Check />
              )}

              <Typography variant="h4" mt={2} mb={1}>
                {t(riskLabel)}
              </Typography>

              <Typography variant="body1">
                <Link to={`/libraries/terms-and-conditions/${document.id}`}>
                  {document.name}
                </Link>
              </Typography>
            </CardContent>
          </Card>
        )}

      <Box>
        {latestOutput?.termsAndConditions?.scores?.map((score) => (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <ListItem disablePadding disableGutters>
                <ListItemIcon>
                  <Avatar
                    variant="rounded"
                    sx={{
                      width: 24,
                      height: 24,
                      bgcolor: (theme) => theme.palette.grey[900],
                    }}
                  >
                    {score.articleNumber}
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Grid
                      container
                      direction="row"
                      gap={5}
                      size={12}
                      flexWrap="nowrap"
                      alignItems="center"
                    >
                      <Grid size={4}>
                        <Typography variant="body1" textAlign="left">
                          {score.articleTitle}
                        </Typography>

                        {/* <Typography variant="caption">{score.explanation}</Typography> */}
                      </Grid>

                      <Grid size={8}>
                        <Tooltip
                          title={<Typography>{score.explanation}</Typography>}
                        >
                          <div>
                            <ScoreBar
                              variant="determinate"
                              value={(score.score / 10) * 100}
                              hideLabel
                            />
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
            </AccordionSummary>
            <AccordionDetails>
              <Alert
                sx={{ mt: 5 }}
                variant="outlined"
                icon={<OwlAvatarIcon />}
                severity="info"
              >
                <Typography variant="body1">{score.explanation}</Typography>
              </Alert>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

const AIContractDetailSidebar: React.FC<AIContractDetailSidebarProps> = ({
  mode,
  signer,
  signees,
  contract,
  documents,
  document,
  aiOutputs,
  analysisStatus,
  onAnalysis,
  setSignees,
  setSignatures,
  signatures,
  resetSignatures,
  refetchContract,
  setSearchForText,
  handlePromptClick,
  promptIdToExpand,
  setAiOutput,
  setSelectedParagraph,
  sideBarTab,
  setSideBarTab,
  switchToDocument,
}) => {
  const { t } = useTranslation();
  const { id, status } = useParams();
  const { user } = useAuth();
  const theme = useTheme();

  const [badges, setBadges] = useState<{ [key: string]: number }>({});

  const [addVerification] = useAddHumanDataVerificationMutation();

  const { data: prompts = [], isSuccess: promptsLoaded } = useGetPromptsQuery();
  const { data: verifications } = useGetHumanDataVerificationsQuery(
    contract ?? skipToken
  );

  // const [aiOutputs, setAiOutputs] = useState<ContractExtractionResult[]>([]);

  const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const [expanded, setExpanded] = useState<string>();

  const [suggestedPrompts, setSuggestedPrompts] = useState<
    Partial<IPrompt & { relavantParagraphs: Paragraph[] }>[]
  >([]);
  const [promptsSuggestedCount, setPromptsSuggestedCount] = useState<number>(0);

  useEffect(() => {
    const newSuggestedPrompts = aiOutputs
      .map((a) => a.suggestedPrompts)
      .flat()
      .filter((p) => p);

    const withIds = newSuggestedPrompts.map((p) => {
      // generate a randomguid
      const id = uuidv4();

      return {
        ...p,
        id,
        body: p?.body || "",
        author: user.id,
      };
    });

    setSuggestedPrompts(withIds);
  }, [aiOutputs]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSideBarTab(newValue);
  };

  const setBadge = (tabIndex: string, num: number) => {
    setBadges((prev) => ({ ...prev, [tabIndex]: num }));
  };

  const selectParagraph = (
    paragraph?: Paragraph,
    prompt?: IPrompt,
    aiOutput?: ContractExtractionResult
  ) => {
    if (!prompt) {
      setExpanded(undefined);

      if (handlePromptClick) {
        handlePromptClick(undefined);
      }
      return;
    }

    // check if we need to switch to another document
    if (aiOutput?.documentId && document.id !== aiOutput?.documentId) {
      switchToDocument(aiOutput.documentId);

      setTimeout(() => {
        setExpanded(prompt.id);
        setSelectedParagraph(paragraph);
        if (setAiOutput) {
          setAiOutput(aiOutput);
        }

        if (handlePromptClick) {
          handlePromptClick(prompt);
        }
      }, 1300);
    } else {
      setExpanded(prompt.id);
      setSelectedParagraph(paragraph);
      if (setAiOutput) {
        setAiOutput(aiOutput);
      }

      if (handlePromptClick) {
        handlePromptClick(prompt);
      }
    }
  };

  useEffect(() => {
    if (promptIdToExpand) {
      // findHighlight(null, true, promptFind);
      setExpanded(promptIdToExpand);
    }
  }, [promptIdToExpand]);

  // useEffect(() => {
  //   const sorted = aiOutputsMainContract
  //     .concat(aiOutputsAttachments)
  //     .sort((a, b) => {
  //       // sort the aiOutputs by date
  //       return (
  //         new Date(b.processedAt).getTime() - new Date(a.processedAt).getTime()
  //       );
  //     });

  //   setAiOutputs(sorted);
  // }, [aiOutputsMainContract, aiOutputsAttachments]);

  // useEffect(() => {
  //   if (attachments && attachments?.length > 0) {
  //     attachments.forEach((attachment) => {
  //       getAttachmentAIOutput(attachment)
  //         .unwrap()
  //         .then((result) => {
  //           if (result) {
  //             // add the result to the aiOutputs
  //             const newAIOutputs = aiOutputsAttachments.concat(result);
  //             setAIOutputAttachments(newAIOutputs);
  //           }
  //         });
  //     });
  //   } else {
  //     setAIOutputAttachments([]);
  //   }
  // }, [attachments]);

  const promptWasFound = (p: IPrompt) => {
    if (aiOutputs?.length === 0) return false;

    const latestForPrompt = aiOutputs.filter((a) =>
      // !attachments?.some((att) => att.id === a.documentId) &&
      a.prompts?.some((b) => b.promptID === p.id)
    );

    if (latestForPrompt.length === 0) return false;

    return latestForPrompt[0].prompts?.some(
      (pr) => pr.extractionResult?.infoFound
    );
  };

  const promptIsExcluded = (p: IPrompt): boolean => {
    return p.id && contract?.exludedPromptIds?.includes(p.id) ? true : false;
  };

  const getBadgeContent = (isVerified: boolean, hasAiSuggestion: boolean) => {
    return isVerified ? (
      <Check sx={{ maxWidth: 8, maxHeight: 16 }} />
    ) : hasAiSuggestion ? (
      <QuestionMark sx={{ maxWidth: 8, maxHeight: 16 }} />
    ) : (
      <Close sx={{ maxWidth: 8, maxHeight: 16 }} />
    );
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        width: "100%",
        mb: 6,
        py: 3,
        px: 3,

        backgroundColor: "background.default",
        minHeight: 450,
        overflow: "auto",
        maxHeight: "calc(100vh - 365px)",
      }}
    >
      <Box mb={5}>
        <AskMeAnything placeholder={"Ask a question about this contract"} />
      </Box>

      {contract && (
        <Paper sx={{ mb: 4 }}>
          <Tabs
            onChange={handleChange}
            value={sideBarTab}
            orientation="horizontal"
            // centered
            variant="scrollable"
            // sx={{
            //   "& .MuiTabs-flexContainer": {
            //     flexWrap: "wrap",
            //   },
            //   "& .MuiTabs-indicator": {
            //     borderBottom: "none",
            //   },
            // }}
          >
            <Tab label={t("Basic details")} icon={<ManageAccounts />} />
            {/* <Tab label={t("Deviations")} icon={<WarningAmber />} /> */}
            <Tab label={t("Deviations")} icon={<WarningAmber />} />
            <Tab label={t("Fields found")} icon={<Checklist />} />
            <Tab label={t("Fields not found")} icon={<Cancel />} />
            <Tab label={t("Fields excluded")} icon={<Block />} />
            <Tab label={t("Fields suggested")} icon={<TipsAndUpdates />} />
            <Tab label={t("Contract management")} icon={<Settings />} />
            {/* <Tab label={t("AI assistant")} icon={<OwlAvatarIcon />} /> */}
          </Tabs>
        </Paper>
      )}

      <Grid container direction="column" gap={3}>
        {sideBarTab === SideBarTabIndex.AI_ASSISTANT && (
          <AskMeAnything
            showLabel
            placeholder={"Ask a question about this contract"}
          />
        )}

        {contract && sideBarTab === SideBarTabIndex.CONTRACT_MANAGEMENT && (
          <Stack>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Contract management")}
            </Typography>
            <Accordion disabled>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-airead"
                id="panel-airead-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <AutoAwesome />
                  </ListItemIcon>
                  <ListItemText primary={t("AI_Analysis")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <SidebarRiskAnalysis
                  contract={contract}
                  document={document}
                  isDownLg={isDownLg}
                  analyzing={analysisStatus === AIReadingStatus.ANALYZING}
                  onAnalysis={onAnalysis}
                  isAnalyzed={!!contract?.analyzeStatus}
                  hideTitle
                />
              </AccordionDetails>
              {/* <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions> */}
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-attachments"
                id="panel-attachments-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={badges.attachments}
                      color="info"
                      showZero
                    >
                      <AttachFile />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Attachments")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractAttachments
                  mode={DialogMode.Edit}
                  contract={contract}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("attachments", num)}
                  contractWasAnalysed={aiOutputs.length > 0}
                />
              </AccordionDetails>
            </Accordion>
            {setSignees &&
              setSignatures &&
              resetSignatures &&
              signatures &&
              signees &&
              signer && (
                <Accordion disabled>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-signees"
                    id="panel-signees-header"
                  >
                    <ListItem disablePadding disableGutters>
                      <ListItemIcon>
                        <HistoryEdu />
                      </ListItemIcon>
                      <ListItemText primary={t("Signees")} />
                    </ListItem>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AIContractSigneeManagement
                      mode={
                        status === ContractStatus.SIGNED ||
                        status === ContractStatus.ACTIVE ||
                        status === ContractStatus.ARCHIVED
                          ? DialogMode.ViewOnly
                          : DialogMode.Edit
                      }
                      signer={signer}
                      readOnly={false} // {mode === DialogMode.ViewOnly}
                      contract={contract}
                      signable={false} // signees?.find((s) => s.email === user?.email) !== undefined
                      signees={signees}
                      setSignees={setSignees}
                      setSignatures={setSignatures}
                      signatures={signatures}
                      resetSignatures={resetSignatures}
                      document={document}
                      hideTitle
                    />
                  </AccordionDetails>
                  {/* <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions> */}
                </Accordion>
              )}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-roles"
                id="panel-roles-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <Groups />
                  </ListItemIcon>
                  <ListItemText primary={t("Roles")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <SidebarRoles
                  contract={contract}
                  hideTitle
                  readonly={mode === DialogMode.ViewOnly}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-tasks"
                id="panel-tasks-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={badges.tasks}
                      color="info"
                      showZero
                    >
                      <TaskAlt />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText primary={t("Notes")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractNotes
                  contract={contract}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("tasks", num)}
                />
              </AccordionDetails>
              {/* <AccordionActions>
              <Button>Cancel</Button>
              <Button>Agree</Button>
            </AccordionActions> */}
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-folders"
                id="panel-folders-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <FolderCopy />
                  </ListItemIcon>
                  <ListItemText primary={t("Folders")} />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractFolders contract={contract} hideTitle />
              </AccordionDetails>
            </Accordion>
          </Stack>
        )}

        {contract && sideBarTab === SideBarTabIndex.BASIC_DETAILS && (
          <Stack>
            {/* {contract?.extraContext && (
            <Box>
              {contract.extraContext.map((context) => (
                <Box py={3}>
                  <Typography>{context.property}</Typography>
                  <Box>{context.details}</Box>
                  <Box>{context.locationInText}</Box>
                </Box>
              ))}
            </Box>
          )} */}

            {/* <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Basic details")}
            </Typography> */}

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-category"
                id="panel-category-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract.contractCategory ||
                          contract.procurement?.category
                          ? true
                          : false,
                        aiOutputs?.some(
                          (a) =>
                            a.metadata?.structuralData?.procurement?.category ||
                            a.metadata?.structuralData?.contractCategory
                        )
                          ? true
                          : false
                      )}
                      color={
                        contract.contractCategory ||
                        contract.procurement?.category
                          ? "success"
                          : aiOutputs?.some(
                              (a) =>
                                a.metadata?.structuralData?.procurement
                                  ?.category ||
                                a.metadata?.structuralData?.contractCategory
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Style />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Category")}
                    secondary={
                      contract?.contractCategory
                        ? t(contract.contractCategory.title)
                        : contract.procurement?.category
                        ? t(contract.procurement?.category)
                        : ""
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractCategory
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            {(contract?.buyer?.contactPersons?.length > 0 ||
              contract?.seller?.contactPersons?.length > 0) && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel-contactpersons"
                  id="panel-contactpersons-header"
                >
                  <ListItem disablePadding disableGutters>
                    <ListItemIcon>
                      <StyledBadge
                        badgeContent={getBadgeContent(
                          contract.buyer?.contactPersons?.length > 0 ||
                            contract.seller?.contactPersons?.length > 0,
                          aiOutputs?.some(
                            (a) =>
                              a.metadata?.structuralData?.seller
                                ?.contactPersons ||
                              a.metadata?.structuralData?.buyer?.contactPersons
                          )
                        )}
                        color={
                          contract.buyer?.contactPersons?.length > 0 ||
                          contract.seller?.contactPersons?.length > 0
                            ? "success"
                            : aiOutputs?.some(
                                (a) =>
                                  a.metadata?.structuralData?.seller
                                    ?.contactPersons ||
                                  a.metadata?.structuralData?.buyer
                                    ?.contactPersons
                              )
                            ? "info"
                            : "error"
                        }
                        showZero
                      >
                        <Person />
                      </StyledBadge>
                    </ListItemIcon>
                    <ListItemText
                      primary={t("Contact persons")}
                      secondary={[
                        ...contract.seller?.contactPersons,
                        ...contract.buyer?.contactPersons,
                      ]
                        .map((cp) => cp.name)
                        .join(", ")}
                    />
                  </ListItem>
                </AccordionSummary>
                <AccordionDetails>
                  <ContractContactPersons
                    mode={mode}
                    contract={contract}
                    aiOutputs={aiOutputs}
                    hideTitle
                    documents={documents}
                  />
                </AccordionDetails>
              </Accordion>
            )}

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-enddate"
                id="panel-enddate-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.endDate || !!contract.dates?.endDate,
                        aiOutputs?.some(
                          (a) => a.metadata?.structuralData?.dates
                        )
                      )}
                      color={
                        contract.endDate || contract.dates?.endDate
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.dates
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Event />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("End date")}
                    secondary={
                      contract.dates?.endDate ? (
                        <ContractDatesCard
                          {...contract.dates}
                          viewMode="compact"
                        />
                      ) : (
                        <ContractDatesCard {...contract} viewMode="compact" />
                      )
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractDates
                  contract={contract}
                  documents={documents}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-financials"
                id="panel-financials-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract.budget?.amount || contract.budget?.totalValue
                          ? true
                          : false,
                        aiOutputs?.some(
                          (a) =>
                            a.metadata?.structuralData?.financials?.totalValue
                        )
                      )}
                      color={
                        contract?.budget?.amount || contract?.budget?.totalValue
                          ? "success"
                          : aiOutputs?.some(
                              (a) =>
                                a.metadata?.structuralData?.financials
                                  ?.totalValue
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      {contract?.budget?.currency === "EUR" ? (
                        <Euro />
                      ) : contract?.budget?.currency === "USD" ? (
                        <DollarSign />
                      ) : (
                        <Money />
                      )}
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      // <Badge
                      //   variant="dot"
                      //   color="warning"
                      //   badgeContent=" "
                      //   // overlap="circular"
                      //   anchorOrigin={{
                      //     vertical: "top",
                      //     horizontal: "left",
                      //   }}
                      // >
                      t("Financials")
                      // </Badge>
                    }
                    secondary={
                      contract?.budget && (
                        <FinancialsCard
                          {...contract.budget}
                          viewMode="compact"
                        />
                      )
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractFinancials
                  mode={mode}
                  contract={contract}
                  documents={documents}
                  aiOutputs={aiOutputs}
                  hideTitle
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-jurisdiction"
                id="panel-jurisdiction-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.countryOfJurisdiction,
                        aiOutputs?.some(
                          (a) =>
                            a.metadata?.structuralData?.countryOfJurisdiction
                        )
                      )}
                      color={
                        contract.countryOfJurisdiction
                          ? "success"
                          : aiOutputs?.some(
                              (a) =>
                                a.metadata?.structuralData
                                  ?.countryOfJurisdiction
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Public />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Country of jurisdiction")}
                    secondary={contract?.countryOfJurisdiction}
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractJurisdiction
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-subject"
                id="panel-subject-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.subject,
                        aiOutputs?.some(
                          (a) => a.metadata?.structuralData?.subject
                        )
                      )}
                      color={
                        contract?.subject
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.subject
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Subject />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Subject")}
                    secondary={contract.subject}
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractSubject
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-noticeperiod"
                id="panel-noticeperiod-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract.noticePeriod?.unit &&
                          contract.noticePeriod?.value
                          ? true
                          : false,
                        aiOutputs?.some(
                          (a) => a.metadata?.structuralData?.noticePeriod
                        )
                          ? true
                          : false
                      )}
                      color={
                        contract.noticePeriod?.unit &&
                        contract.noticePeriod?.value
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.noticePeriod
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <PanTool />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Notice period")}
                    secondary={
                      <NoticePeriodCard
                        {...contract.noticePeriod}
                        viewMode="compact"
                      />
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractNoticePeriod
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-parties"
                id="panel-parties-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        contract?.buyer ||
                          contract?.seller ||
                          contract?.vendorName ||
                          contract?.vendorId
                          ? true
                          : false,
                        aiOutputs?.some(
                          (a) =>
                            a.metadata?.structuralData?.seller ||
                            a.metadata?.structuralData?.buyer
                        )
                          ? true
                          : false
                      )}
                      color={
                        contract?.buyer ||
                        contract?.seller ||
                        contract?.vendorName ||
                        contract?.vendorId
                          ? "success"
                          : aiOutputs?.some(
                              (a) =>
                                a.metadata?.structuralData?.seller ||
                                a.metadata?.structuralData?.buyer
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Store />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Parties")}
                    secondary={
                      <>
                        {contract.buyer?.name ? contract.buyer?.name : ""}
                        {contract.buyer?.name && contract.seller?.name
                          ? ", "
                          : ""}
                        {contract.seller?.name ? contract.seller?.name : ""}
                      </>
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractParties
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("parties", num)}
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-summary"
                id="panel-summary-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.summary,
                        aiOutputs?.some(
                          (a) => a.metadata?.structuralData?.summary
                        )
                      )}
                      color={
                        contract?.summary
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.summary
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <MenuBook />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Summary")}
                    secondary={
                      contract.summary &&
                      contract.summary?.substring(0, 100) + "..."
                    }
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractSummary
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  setBadgeCount={(num: number) => setBadge("summary", num)}
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-type"
                id="panel-type-header"
              >
                <ListItem disablePadding disableGutters>
                  <ListItemIcon>
                    <StyledBadge
                      badgeContent={getBadgeContent(
                        !!contract.type,
                        aiOutputs?.some((a) => a.metadata?.structuralData?.type)
                      )}
                      color={
                        contract?.type
                          ? "success"
                          : aiOutputs?.some(
                              (a) => a.metadata?.structuralData?.type
                            )
                          ? "info"
                          : "error"
                      }
                      showZero
                    >
                      <Class />
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Type")}
                    secondary={t(contract.type || "")}
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                <ContractType
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  hideTitle
                  documents={documents}
                />
              </AccordionDetails>
            </Accordion>
          </Stack>
        )}

        {/* {contract && sideBarTab === SideBarTabIndex.SCORES && (
          <Stack>
            {/* {contract?.extraContext && (
            <Box>
              {contract.extraContext.map((context) => (
                <Box py={3}>
                  <Typography>{context.property}</Typography>
                  <Box>{context.details}</Box>
                  <Box>{context.locationInText}</Box>
                </Box>
              ))}
            </Box>
          )} */}

        {/* <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Basic details")}
            </Typography> 

            <ScoresSection contract={contract} aiOutputs={aiOutputs} />
          </Stack>
        )} */}

        {contract && sideBarTab === SideBarTabIndex.SCORES_V2 && (
          <Stack>
            {/* {contract?.extraContext && (
            <Box>
              {contract.extraContext.map((context) => (
                <Box py={3}>
                  <Typography>{context.property}</Typography>
                  <Box>{context.details}</Box>
                  <Box>{context.locationInText}</Box>
                </Box>
              ))}
            </Box>
          )} */}

            {/* <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Basic details")}
            </Typography> */}

            <ScoresV2Section contract={contract} aiOutputs={aiOutputs} />
          </Stack>
        )}

        {contract && sideBarTab === SideBarTabIndex.FIELDS_FOUND && (
          <PromptsSection
            contract={contract}
            prompts={prompts
              ?.filter(
                (p) =>
                  p.enabled &&
                  !p.hidden &&
                  promptWasFound(p) &&
                  !promptIsExcluded(p)
              )
              .sort((a, b) => {
                // sort on the translated title
                const aCategory = a.title ? t(a.title)?.toLowerCase() : "";
                const bCategory = b.title ? t(b.title)?.toLowerCase() : "";

                if (aCategory < bCategory) {
                  return -1;
                }
                if (aCategory > bCategory) {
                  return 1;
                }
                return 0;
              })}
            mode={mode}
            aiOutputs={aiOutputs}
            selectParagraph={selectParagraph}
            expanded={expanded}
            infoFound={true}
            verifications={verifications}
            documents={documents}
          />
        )}

        {contract && sideBarTab === SideBarTabIndex.FIELDS_NOT_FOUND && (
          <PromptsSection
            contract={contract}
            prompts={prompts
              ?.filter(
                (p) =>
                  p.enabled &&
                  !p.hidden &&
                  !promptWasFound(p) &&
                  !promptIsExcluded(p)
              )
              .sort((a, b) => {
                // sort on the translated title
                const aCategory = a.title ? t(a.title)?.toLowerCase() : "";
                const bCategory = b.title ? t(b.title)?.toLowerCase() : "";

                if (aCategory < bCategory) {
                  return -1;
                }
                if (aCategory > bCategory) {
                  return 1;
                }
                return 0;
              })}
            mode={mode}
            aiOutputs={aiOutputs}
            selectParagraph={selectParagraph}
            expanded={expanded}
            infoFound={false}
            verifications={verifications}
            documents={documents}
          />
        )}

        {contract && sideBarTab === SideBarTabIndex.FIELDS_EXCLUDED && (
          <PromptsSection
            contract={contract}
            prompts={prompts
              ?.filter((p) => promptIsExcluded(p))
              .sort((a, b) => {
                // sort on the translated title
                const aCategory = a.title ? t(a.title)?.toLowerCase() : "";
                const bCategory = b.title ? t(b.title)?.toLowerCase() : "";

                if (aCategory < bCategory) {
                  return -1;
                }
                if (aCategory > bCategory) {
                  return 1;
                }
                return 0;
              })}
            mode={mode}
            aiOutputs={aiOutputs}
            selectParagraph={selectParagraph}
            expanded={expanded}
            infoFound={false}
            verifications={verifications}
            documents={documents}
          />
        )}

        {contract && sideBarTab === SideBarTabIndex.FIELDS_SUGGESTED && (
          <Box display="flex" flexDirection="column" gap={5}>
            {/* <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Fields found")}
            </Typography> */}

            {suggestedPrompts?.map((suggestedPrompt) => {
              return (
                <ContractSuggestedPromptAccordion
                  key={`contractSuggestedPromptAccordion-${suggestedPrompt.id}`}
                  suggestedPrompt={suggestedPrompt as any}
                  contract={contract}
                  mode={mode}
                  aiOutputs={aiOutputs}
                  selectParagraph={selectParagraph}
                  isExpanded={expanded === suggestedPrompt.id}
                  infoFound={true}
                  documents={documents}
                  // attachments={attachments}
                />
              );
            })}
          </Box>
        )}

        {aiOutputs?.length > 0 && aiOutputs[0].articles?.length > 0 && (
          <Box display="flex" flexDirection="column" gap={0}>
            {/* <Typography variant="h6" sx={{ mb: 2 }}>
              {t("Fields found")}
            </Typography> */}

            {aiOutputs?.length > 0 &&
              aiOutputs[0].articles.map((article) => {
                return (
                  <ArticleAccordion
                    key={`articleAccordion-${article.id}`}
                    article={article as any}
                    isExpanded={true}
                    mode={mode}
                    selectParagraph={selectParagraph}
                  />
                );
              })}
          </Box>
        )}
      </Grid>
    </Paper>
  );
};

export default AIContractDetailSidebar;

import { Form, Formik } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
  FormControl,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import { DialogMode } from "../../types/dialogmode";
import { useTranslation } from "react-i18next";
import { IFolder } from "../../types/folder";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

interface AutocompleteOption {
  id: string;
  label: string;
  logo: string;
  description?: string;
}

interface IFolderFormProps {
  mode: DialogMode;
  folder?: IFolder;
  initialValues?: IFolder;
  addFolder: (values: IFolder) => void;
  updateFolder: (values: IFolder) => void;
}

function FolderForm({ ...props }: IFolderFormProps) {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("Required")!),
  });

  const handleSubmit = async (
    values: any,
    { resetForm, errors, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      if (errors) {
        setErrors(errors);
        setSubmitting(false);
        return;
      }

      console.log("submitting form");
      if (props.mode === DialogMode.Add) {
        await props.addFolder(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === DialogMode.Edit) {
        await props.updateFolder(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const initialValues: IFolder = props.folder
    ? { ...props.folder }
    : props.initialValues
    ? { ...props.initialValues }
    : {
        id: "",
        name: "",
      };

  const fieldVariant = "standard";
  const errorFieldVariant = "filled";

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Box my={3}>
          {status && status.sent && (
            <Alert severity="success" my={3}>
              {t("Your data has been submitted successfully!")}
            </Alert>
          )}

          <Form id="folder-form" onSubmit={handleSubmit}>
            <Grid container gap={6} direction="column">
              <FormControl fullWidth>
                <TextField
                  name="name"
                  label={t("Name")}
                  value={values.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={
                    touched.name && errors.name ? errors.name.toString() : null
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                  variant={
                    touched.name && errors.name
                      ? errorFieldVariant
                      : fieldVariant
                  }
                />
              </FormControl>
            </Grid>
          </Form>
        </Box>
      )}
    </Formik>
  );
}

export default FolderForm;

import { useState } from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  TextField as MuiTextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import { Close as CloseIcon, Preview } from "@mui/icons-material";

import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { IDriveItem } from "../../types/driveItem";
import DriveItemCard from "../cards/DriveItemCard";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  startDate: Yup.date().optional(),
  endDate: Yup.date().optional(),
  value: Yup.number().optional(),
});

type SharePointFilesDialogProps = {
  driveItems: IDriveItem[];
};

const SharePointFilesDialog = (props: SharePointFilesDialogProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        variant="text"
        onClick={() => setOpen(true)}
        startIcon={<Preview />}
      >
        {t("View files", { count: props.driveItems?.length })}
      </Button>

      <Dialog
        open={open}
        maxWidth="xl"
        fullWidth
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {t("View files", { count: props.driveItems?.length })}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          {/* <DialogContentText>Files in this drive</DialogContentText> */}

          <Grid
            container
            spacing={3}
            p={4}
            direction="row"
            sx={
              {
                // backgroundColor: "background.default",
              }
            }
          >
            {props.driveItems?.map((item: any) => (
              <Grid key={`drive-item-${item.id}`} width={250} height={350}>
                <DriveItemCard driveItem={item} />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSaveClick()} color="primary">
            Save
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SharePointFilesDialog;

// ** React Imports
import { Document, Page } from "react-pdf";
import React, { useEffect, useRef, useState } from "react";

// https://www.npmjs.com/package/react-pdf
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// ** MUI Imports
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  Fab,
  Fade,
  Grow,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";

import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";

import { ChevronLeft, ChevronRight, Edit } from "react-feather";
import { useTranslation } from "react-i18next";

import { IDocument } from "../../types/document";
import { ISignature, ISignee } from "../../types/signature";
import AIDocumentSignature from "../../pages/main/contracts/components/AIDocumentSignature";
import { grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { selectFile } from "../../redux/slices/filesSlice";
import { RootState } from "../../redux/store";
import SignatureFields from "./SignatureFields";
import { PDFDocumentProxy } from "pdfjs-dist";
import { IPrompt } from "../../types/prompt";

const elevation2 =
  "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important";
const elevation3 =
  "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12) !important";

const SIGN_AREA_WIDTH = 180;
const SIGN_AREA_HEIGHT = 75;

const StyledSignArea = styled(Box)`
  position: relative;
  width: ${SIGN_AREA_WIDTH}px;
  height: ${SIGN_AREA_HEIGHT}px;
  border: 1px dashed;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
`;
const SignIcon = styled(Box)`
  position: absolute;
  top: -10px;
  left: 10px;
`;

const SignatureImg = styled("img")`
  width: 150px;
  height: auto;
  opacity: 1;
  position: absolute;
  zindex: -1;
`;

const ElevatedPaper = styled(Paper)({
  boxShadow: elevation3,
  margin: 4,
});

const HoverBox = styled(Box)`
  // opacity: 0;
  transform: translate(-50%);
  transition: opacity ease-in-out 0.2s;
  z-index: 200;
  // background-color: ${(props) => props.theme.palette.background.default};

  // &:hover {
  //   opacity: 1;
  // }
`;

const HighlightedParagraph = styled(Box)<{ width: number }>(
  {
    padding: 4,
    borderRadius: 4,
    position: "relative",
    zIndex: 100,
    height: `${SIGN_AREA_HEIGHT}px`,
    border: "1px solid",
    // borderColor: (props) => props.theme.palette.primary.main,
    display: "flex",
    userSelect: "none",
  },
  ({ width }) => ({
    width,
  })
);

const SignArea: React.FC<{
  signer?: ISignee;
  signature: ISignature;
  signable?: boolean;
  onOpenSignaturePad: (email: string) => void;
  onMoveToFirstPage: (email: string) => void;
  onMoveToPrevPage: (email: string) => void;
  onMoveToNextPage: (email: string) => void;
  onMoveToLastPage: (email: string) => void;
  numPages: number;
}> = ({
  signer,
  signature: sig,
  signable,
  onOpenSignaturePad,
  onMoveToFirstPage,
  onMoveToNextPage,
  onMoveToPrevPage,
  onMoveToLastPage,
  numPages,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex" }}>
      <StyledSignArea
        sx={{
          borderColor: sig.email === signer?.email ? "#22A121" : "#FDA018",
          pointerEvents:
            sig.email !== signer?.email && signable ? "none" : "all",
          color: "black",
          cursor:
            sig.draggable && !sig.isSigned
              ? "grab"
              : signable && sig.email === signer?.email && !sig.signedDate
              ? "pointer"
              : "default",
          "&:active": {
            cursor: sig.draggable && !sig.isSigned ? "grab" : "default",
          },
          "&:hover": {
            backgroundColor:
              sig.email === signer?.email ? grey[100] : "initial",
          },
        }}
        onClick={() =>
          signable && sig.email === signer?.email && !sig.signedDate
            ? onOpenSignaturePad(sig.email)
            : null
        }
      >
        <SignIcon
          sx={{
            color: sig.email === signer?.email ? "#22A121" : "#FDA018",
          }}
        >
          <Edit />
        </SignIcon>
        <Typography>{t("Sign for")}</Typography>
        <Typography fontWeight="bold">{sig.displayName}</Typography>
        {signable && sig.email === signer?.email && !sig.signedDate && (
          <Typography variant="caption">({t("click here")})</Typography>
        )}
      </StyledSignArea>
      {sig.draggable && !sig.isSigned && numPages > 1 && (
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: -1.5 }}>
          <Tooltip title={t("Move to first page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToFirstPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <FirstPage sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to previous page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToPrevPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <NavigateBefore sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to next page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToNextPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <NavigateNext sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title={t("Move to last page")}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => onMoveToLastPage(sig.email)}
              sx={{ width: 24, height: 22 }}
            >
              <LastPage sx={{ rotate: "90deg" }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export interface IPDFViewerProps {
  file?: File;
  fileUrl?: string;
  document?: IDocument;
  arrayBuffer?: ArrayBuffer;
  viewMode?: "withMenu" | "pageOnly";
  signable?: boolean;
  signer?: ISignee;
  signatures?: Array<ISignature>;
  onSaveSignature?: () => any;
  setSignatures?: React.Dispatch<React.SetStateAction<Array<ISignature>>>;
  setSignatureSizePercentValue?: React.Dispatch<
    React.SetStateAction<{ width: number; height: number }>
  >;
  toggleSignaturePad?: boolean;
  onSuccess?: () => void;
  paging?: boolean;
  setProgress?: (progress: number) => void;
  searchForText?: string;
  searchResultIndex?: number;
  setSearchResults?: React.Dispatch<React.SetStateAction<number>>;
  prompt?: IPrompt | null;
}
function PDFViewer({ ...props }: IPDFViewerProps) {
  const { t } = useTranslation();
  // https://github.com/wojtekmaj/react-pdf/wiki/Recipes#display-all-pages
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [paperHovered, setPaperHovered] = useState(false);
  const [ready, setReady] = useState(false);
  const [turningPage, setTurningPage] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileContent, setFileContent] = useState<ArrayBuffer>();
  const [pageWidth, setPageWidth] = useState(700);
  const pdfViewerRef = useRef(null);
  const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy>();
  const [pdfPageHeight, setPdfPageHeight] = useState<number>(0);
  const [markElements, setMarkElements] = useState<NodeListOf<Element> | []>(
    []
  );

  const fileBuffer = useSelector<RootState, ArrayBuffer | undefined>((state) =>
    props.document ? selectFile(props.document.id || "")(state) : undefined
  );

  useEffect(() => {
    if (pdfViewerRef.current) {
      // get the width of the element
      const width = (pdfViewerRef.current as any).clientWidth;

      setPageWidth(width - 40);
    }
  }, [pdfViewerRef.current]);

  useEffect(() => {
    setReady(false);
  }, [props.file, props.fileUrl, props.arrayBuffer]);

  useEffect(() => {
    props.setSearchResults && props.setSearchResults(markElements.length);
  }, [markElements]);

  useEffect(() => {
    // pass the progress to the parent component
    if (props.setProgress) {
      props.setProgress(progress);
    }
  }, [progress]);

  const viewerProps = {
    file:
      props.fileUrl || props.file || fileContent || props.arrayBuffer || null,
  };

  useEffect(() => {
    if (props.document?.id && !props.file) {
      // the id changed, reset the file content
      setFileContent(undefined);
      setReady(false);
    }
  }, [props.document?.id]);

  useEffect(() => {
    if (props.document?.id && !props.file && !fileContent) {
      if (fileBuffer) {
        setFileContent(fileBuffer);
      }
    }
  }, [fileBuffer, fileContent, props.file, props.document?.id]);

  useEffect(() => {
    window.scrollTo(0, scrollPosition);

    setTurningPage(true);
    setTimeout(() => {
      setTurningPage(false);
    }, 1500);
  }, [pageNumber]);

  const onDocumentLoadSuccess = (args: any) => {
    setNumPages(args?.numPages);

    if (props.onSuccess) {
      props.onSuccess();
    }

    setReady(true);
    // setDocumentLoaded(true);
    // setPageNumber(pageNumber);
  };

  const workerUrl = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  pdfjs.GlobalWorkerOptions.workerSrc = workerUrl;

  // signature----------------------------------------------------------------------------------
  const {
    signer,
    signable,
    signatures,
    setSignatures,
    setSignatureSizePercentValue,
    onSaveSignature,
    toggleSignaturePad,
  } = props;
  const [showSignaturePad, setSignaturePad] = useState(false);
  const [documentSize, setDocumentSize] = useState({ width: 0, height: 0 });
  const documentRef = useRef<HTMLDivElement>(null);
  const highlightRef = useRef<HTMLDivElement>(null);
  const [highlightPosition, setHighlightPosition] = useState({ y1: 0, y2: 0 });
  const isSetHighlight = useRef(true);

  useEffect(() => {
    if (
      !isSetHighlight.current &&
      highlightPosition.y1 &&
      highlightPosition.y2
    ) {
      document.getElementById("hightlight-box")?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [isSetHighlight.current, highlightPosition]);

  useEffect(() => {
    if (typeof toggleSignaturePad === "boolean") {
      setSignaturePad((prev) => !prev);
    }
  }, [toggleSignaturePad]);

  useEffect(() => {
    const documentElement = documentRef.current;
    if (documentElement) {
      setDocumentSize({
        width: documentElement.clientWidth,
        height: documentElement.clientHeight,
      });
    }
  }, [documentRef.current?.clientWidth, documentRef.current?.clientHeight]);

  const handleOpenSignaturePad = (email: string) => {
    setSignaturePad(true);
  };
  const handleCloseSignaturePad = () => {
    setSignaturePad(false);
  };

  const handleSave = (signatureUrl: string) => {
    if (setSignatures) {
      setSignatures((prev) => {
        const newSignatures = prev.map((sig) => {
          if (sig.email === signer?.email) {
            return {
              ...sig,
              signatureUrl,
              signedDate: new Date().toISOString(),
              isSigned: true,
              isApproved: true,
            };
          } else {
            return { ...sig };
          }
        });

        return newSignatures;
      });
    }

    if (onSaveSignature) {
      onSaveSignature();
    }

    handleCloseSignaturePad();
  };

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    setScrollPosition(window.scrollY);
    if (pageNumber > 1) {
      changePage(-1);
    }
  }

  function nextPage() {
    setScrollPosition(window.scrollY);

    if (pageNumber < numPages) {
      changePage(1);
    }
  }

  // const PDFPage = ({
  //   data,
  //   index,
  //   style,
  // }: {
  //   data: any;
  //   index: number;
  //   style: any;
  // }) => (
  //   <div style={style}>
  //     <Page
  //       key={`page-${index + 1}`}
  //       pageNumber={index + 1}
  //       loading={"loading page..."}
  //     />
  //   </div>
  // );

  const PDFPage = ({ index, style }: { index: number; style: any }) => (
    <div style={style}>
      <Box key={index} pb={4}>
        <Grow
          in={ready}
          easing={"ease-in"}
          style={{ transformOrigin: "top" }}
          {...(ready ? { timeout: 500 } : {})}
        >
          <ElevatedPaper>
            <Page
              key={`page-${index + 1}`}
              pageNumber={index + 1}
              loading={""}
              width={pageWidth > 900 ? 900 : pageWidth}
              customTextRenderer={({
                str,
                width,
                height,
                transform,
                pageIndex,
                pageNumber,
                itemIndex,
              }) => {
                // if (props.prompt && isSetHighlight.current) {
                //   let percent =
                //     (pdfPageHeight - transform[transform.length - 1]) /
                //     pdfPageHeight;
                //   let heightActual =
                //     (documentSize.height - numPages * 18.5) / numPages;
                //   let positionY = heightActual * percent;
                //   if (
                //     props.prompt?.locationInText?.start &&
                //     str.includes(props.prompt?.locationInText?.start)
                //   ) {
                //     setHighlightPosition((pre) => ({
                //       ...pre,
                //       y1:
                //         pageIndex * heightActual +
                //         positionY -
                //         height -
                //         8 +
                //         pageIndex * 18.5,
                //     }));
                //   }

                //   if (
                //     props.prompt?.locationInText?.end &&
                //     isSetHighlight.current &&
                //     str.includes(props.prompt?.locationInText?.end)
                //   ) {
                //     setHighlightPosition((pre) => {
                //       if (pre.y1) {
                //         isSetHighlight.current = false;
                //         return {
                //           ...pre,
                //           y2:
                //             pageIndex * heightActual +
                //             positionY -
                //             8 +
                //             pageIndex * 18.5,
                //         };
                //       }
                //       return pre;
                //     });
                //   }
                // }
                if (
                  props.searchForText &&
                  str.indexOf(props.searchForText) > -1
                ) {
                  console.log("found", str);
                  console.log(transform);

                  // add a ref to the highlighted paragraph

                  // make a new ref
                  const ref = `${itemIndex}-highlight-ref`;

                  // scroll to the first ref
                  setTimeout(() => {
                    // select all elements with the ref

                    const elements = document.querySelectorAll(
                      "mark[id$='-highlight-ref']"
                    );

                    // setMarkElements(elements);

                    if (elements.length > 0) {
                      if (props.searchResultIndex) {
                        // go to the element with the index
                        const element =
                          elements[props.searchResultIndex] ?? null;

                        if (element) {
                          element.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }
                      } else {
                        // select the first element
                        const firstElement = elements[0];

                        firstElement.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }
                    }
                  }, 1000);

                  const newStr = str.replace(
                    new RegExp(props.searchForText, "g"),
                    (match) => `<mark id="${ref}">${match}</mark>`
                  );

                  return newStr;

                  // return `<mark id="${ref}">${str}</mark>`;
                }
                return str;
              }}
            />
          </ElevatedPaper>
        </Grow>

        {ready && numPages > 0 && (
          <Fade in={ready} timeout={2600}>
            {/* Since the pages are growing in in 500ms, show the page numbers a bit later */}
            <Box display="flex" justifyContent="center" pt={2} pb={3}>
              <Typography variant="caption">
                {t("pages", {
                  current: index + 1,
                  total: numPages,
                })}
              </Typography>
            </Box>
          </Fade>
        )}
      </Box>
    </div>
  );

  // const goToSection = (section: string) => {
  //   if (viewerProps.file) {
  //     // for each page, search for the text
  //     const max = pdfDocument?.numPages || 1;
  //     for (let i = 1; i <= max; i++) {
  //       pdfDocument?.getPage(i).then((page) => {
  //         console.log("page", page);
  //         page.getTextContent().then((content) => {
  //           console.log(content);

  //           content.items.forEach((item: any) => {
  //             if (item?.str?.indexOf(section) > -1) {
  //               console.log("found", item);

  //               console.log(documentSize);

  //               const pageHeight = documentSize.height / max;

  //               setHighlightPosition({
  //                 x: item.transform[4],
  //                 y: pageHeight * (i - 1),
  //               });
  //             }
  //           });
  //         });
  //       });
  //     }
  //   }
  // };

  return (
    <Box>
      {/* <LinearProgress variant="determinate" value={progress * 100} /> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          background: undefined,
          // height: props.height || "36vh",
          // maxWidth: "50vw",
          // overflowY: "auto",
          // overflowX: "scroll",
          minWidth: 300,
          minHeight: 300,
          // width: "100%",
        }}
        ref={pdfViewerRef}
      >
        {viewerProps.file && (
          <Document
            file={viewerProps.file}
            onLoadSuccess={async (pdf) => {
              // search on every page for the text
              setPdfDocument(pdf as any);

              onDocumentLoadSuccess(pdf);
              const page = await pdf.getPage(1);
              setPdfPageHeight(page.view[page.view.length - 1]);
            }}
            loading={null}
            onMouseEnter={() => setPaperHovered(true)}
            // onMouseLeave={() => setPaperHovered(false)}
            onBlur={() => setPaperHovered(false)}
            onLoadStart={() => {
              setProgress(0.05);
            }}
            onLoadProgress={(progressData: any) => {
              console.log("progressData", progressData);
              setProgress(progressData.loaded / progressData.total);
            }}
          >
            <Box ref={documentRef} position="relative">
              <Box
                sx={{
                  backgroundColor: "#e4d62f4f",
                  minHeighteight: "16px",
                  position: "absolute",
                  zIndex: 3,
                  top: highlightPosition.y1 + "px",
                }}
                width="100%"
                height={Math.abs(highlightPosition.y2 - highlightPosition.y1)}
                id="hightlight-box"
              ></Box>
              <Box sx={{ position: "absolute", zIndex: 3, inset: 0 }}>
                <SignatureFields
                  signatures={signatures}
                  documentRef={documentRef}
                  documentSize={documentSize}
                  handleOpenSignaturePad={handleOpenSignaturePad}
                  numPages={numPages}
                  setSignatures={setSignatures}
                  signable={signable}
                  signer={signer}
                  onSaveSignature={onSaveSignature}
                  setSignatureSizePercentValue={setSignatureSizePercentValue}
                />
              </Box>

              {/* <Box sx={{ position: "absolute", zIndex: 3, inset: 0 }}>
                <Draggable
                  key={"0"}
                  bounds="parent"
                  disabled={true} // disable dragging for signed signatures
                  position={highlightPosition}
                >
                  <HighlightedParagraph width={pageWidth - 300}>
                    De vraag is alleen
                  </HighlightedParagraph>
                </Draggable>
              </Box> */}

              <Box>
                {numPages === 1 || props.paging ? (
                  <Box key="1" position="relative">
                    {PDFPage({ index: pageNumber - 1, style: {} })}

                    {numPages &&
                      numPages > 1 &&
                      props.paging &&
                      paperHovered && (
                        <Fade in={paperHovered} timeout={600} appear>
                          <HoverBox
                            sx={{
                              position: "absolute",
                              bottom: "50%",
                              left: "50%",
                              display: "flex",
                              alignItems: "center",
                              height: 40,
                              borderRadius: 1,
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                            flexDirection="row"
                            className="hoverbox"
                          >
                            <Fab
                              disabled={pageNumber <= 1}
                              onClick={previousPage}
                              size="large"
                              color="info"
                            >
                              <ChevronLeft />
                            </Fab>

                            {/* <Typography
                                  variant="subtitle2"
                                  paddingX={1}
                                  noWrap
                                >
                                  {pageNumber || (numPages ? 1 : "--")}{" "}
                                  {t("of")} {numPages || "--"}
                                </Typography> */}

                            <Fab
                              disabled={!numPages || pageNumber >= numPages}
                              onClick={nextPage}
                              size="large"
                              color="info"
                            >
                              <ChevronRight />
                            </Fab>
                          </HoverBox>
                        </Fade>
                      )}
                  </Box>
                ) : numPages && numPages > 0 ? (
                  Array.from(new Array(numPages), (el, index) => (
                    <Box key={index} pb={4}>
                      {PDFPage({ index, style: {} })}
                    </Box>
                  ))
                ) : (
                  <span />
                )}
              </Box>

              <Dialog onClose={handleCloseSignaturePad} open={showSignaturePad}>
                {/* <SignaturePad ref={(ref) => setSigCanvas(ref)} onEnd={() => setSigned(true)} canvasProps={{ style: { width: "70vw", height: "700px" } }} />
                    <Box sx={{ position: "absolute", right: 4, top: 4 }}>
                      <IconButton onClick={handleClear}>
                        <RestartAlt />
                      </IconButton>
                      <IconButton onClick={handleSave}>
                        <Save />
                      </IconButton>
                    </Box> */}
                <AIDocumentSignature
                  onSign={handleSave}
                  onCancel={handleCloseSignaturePad}
                />
              </Dialog>
            </Box>
          </Document>
        )}
      </Box>
      {/* {numPages > 1 && (
        <Grid
          item
          sx={{
            transitionDelay: documentLoaded ? "800ms" : "0ms",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {!viewAll ? (
            <Grid container flexDirection="column">
              <Grid justifyContent="center" display="flex">
                <Pagination count={numPages} page={pageNumber} variant="outlined" color="secondary" onChange={handlePageChange} />
              </Grid>
              <Grid justifyContent="center" display="flex">
                <Button onClick={() => setViewAll(true)}>View all</Button>
              </Grid>
            </Grid>
          ) : (
            <Button onClick={() => setViewAll(false)}>Show paging</Button>
          )}
        </Grid>
      )} */}
    </Box>
  );
}

export default React.memo(PDFViewer);
// export default DocumentViewer;

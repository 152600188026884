import { Form, Formik } from "formik";
import * as Yup from "yup";
import styled from "@emotion/styled";

import {
  Autocomplete,
  Alert as MuiAlert,
  TextField as MuiTextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";

import { DialogMode } from "../../types/dialogmode";
import useAuth from "../../hooks/useAuth";
import { IPrompt } from "../../types/prompt";
import { useTranslation } from "react-i18next";
import { useGetContractCategoriesQuery } from "../../redux/slices/indexApiSlice";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const validationSchemaPrompt = Yup.object().shape({
  body: Yup.string().required("Required"),
});

interface IPromptFormProps {
  mode: DialogMode;
  addPrompt: (Prompt: IPrompt) => Promise<void>;
  updatePrompt: (Prompt: IPrompt) => Promise<void>;
  prompt?: IPrompt;
}

function PromptForm({ ...props }: IPromptFormProps) {
  const { t } = useTranslation();
  // create a ref
  // const formikRef = useRef<FormikProps<any>>(null);

  const { user } = useAuth();

  const { data: contractCategories } = useGetContractCategoriesQuery();

  const handleSubmit = async (
    values: any,
    { resetForm, setErrors, setStatus, setSubmitting }: any
  ) => {
    try {
      console.log("submitting form");
      if (props.mode === "add") {
        await props.addPrompt(values);

        //   resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else if (props.mode === "edit") {
        await props.updatePrompt(values);

        // resetForm();
        setStatus({ sent: true });
        setSubmitting(false);
      } else {
        setStatus({ sent: false });
        setErrors({ submit: "Mode undefined" });
        setSubmitting(false);
      }
    } catch (error: any) {
      setStatus({ sent: false });
      setErrors({ submit: error.Prompt });
      setSubmitting(false);
    }
  };

  // useEffect(() => {
  //   if (props.submit) {
  //     // if (formikRef.current) {
  //     //   // submit the form
  //     //   formikRef?.current?.handleSubmit();
  //     // }
  //   }
  // }, [props.submit]);

  // const initialValues = {
  //   name: props.contract?.name,
  //   startDate: props.contract?.startDate,
  //   endDate: props.contract?.endDate,
  //   value: props.contract?.value,
  // };

  const blankPrompt: IPrompt = {
    id: "",
    body: "",
    author: user?.id,
    title: "",
    contractCategoryIds: [],
    enabled: true,
    hidden: false,
  };

  const initialValues: IPrompt = props.prompt ? props.prompt : blankPrompt;

  const fieldVariant = "standard"; // "outlined";
  const errorFieldVariant = "filled";

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaPrompt}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        isValid,
      }) => (
        <Grid container mb={6}>
          {errors && !isValid && !status?.sent && (
            <Grid>
              <Alert severity="error" my={3}>
                {t("An error occurred!")}
                {errors && Object.keys(errors).length > 0 && (
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    {JSON.stringify(errors, null, 2)}
                  </pre>
                )}
              </Alert>
            </Grid>
          )}

          <Grid width="100%">
            <Form id="prompt-form" onSubmit={handleSubmit}>
              <Grid container gap={3} direction="column">
                <Grid>
                  {contractCategories && contractCategories.length > 0 && (
                    <Autocomplete
                      multiple
                      options={contractCategories}
                      getOptionLabel={(contractCategory) =>
                        contractCategory.title
                      }
                      onChange={(event, selected) => {
                        setFieldValue(
                          "contractCategoryIds",
                          selected.map((c) => c.id)
                        );
                      }}
                      value={contractCategories.filter(
                        (c) =>
                          c?.id && values?.contractCategoryIds?.includes(c.id)
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Category")}
                          variant={fieldVariant}
                          error={Boolean(
                            touched.contractCategoryIds &&
                              errors.contractCategoryIds
                          )}
                          helperText={
                            touched.contractCategoryIds &&
                            errors.contractCategoryIds
                          }
                          my={2}
                        />
                      )}
                    />
                  )}
                </Grid>

                <Grid>
                  <TextField
                    name="title"
                    label={t("Title")}
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant={
                      touched.title && errors.title
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={false}
                  />
                </Grid>

                <Grid height={500}>
                  <TextField
                    name="body"
                    label={t("Prompt")}
                    value={values.body}
                    error={Boolean(touched.body && errors.body)}
                    fullWidth
                    // helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    // onPaste={(e) => {
                    //   e.preventDefault();
                    //   let text: string = e.clipboardData.getData("text/plain");

                    //   // trim start and end
                    //   text = text.trim();
                    //   text = text.replace(/^\s+|\s+$/g, "");

                    //   setFieldValue("body", text);
                    // }}
                    variant={
                      touched.body && errors.body
                        ? errorFieldVariant
                        : fieldVariant
                    }
                    my={2}
                    multiline={true}
                    minRows={4}
                  />
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

export default PromptForm;

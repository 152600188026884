import { useTranslation } from "react-i18next";
import { Box, Typography, Chip } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IContract } from "../../../../types/contract";
import { ContractExtractionResult } from "../../../../types/aiOutput";
import { useGetHumanDataVerificationsQuery } from "../../../../redux/slices/indexApiSlice";
import AISuggestion from "./AISuggestion";
import VerifiedAISuggestion from "./VerifiedAISuggestion";
import { DialogMode } from "../../../../types/dialogmode";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { IDocument } from "../../../../types/document";
import { IContractCategory } from "../../../../types/contractCategory";

interface IContractCategoryProps {
  contract: IContract;
  mode: DialogMode;
  aiOutputs: ContractExtractionResult[];
  hideTitle?: boolean;
  documents: IDocument[];
}

const CategoryCard = ({
  contractCategory,
  explanation,
}: {
  contractCategory?: IContractCategory;
  explanation?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Grid container size={12} gap={2}>
      {contractCategory && (
        <Typography variant="body1">{contractCategory.title}</Typography>
      )}

      {explanation && <Typography variant="body1">{explanation}</Typography>}
    </Grid>
  );
};

function ContractCategory({ ...props }: IContractCategoryProps) {
  const { contract, mode, documents } = props;
  const { t } = useTranslation();

  const { data: verifications } = useGetHumanDataVerificationsQuery(contract);

  const aiOutputs = props.aiOutputs?.filter(
    (aiOutput) => aiOutput.metadata?.structuralData?.contractCategory
  );

  return (
    <Box pb={2}>
      {!props.hideTitle && (
        <Typography variant="h6" mb={2}>
          {t("Category")}
        </Typography>
      )}
      {contract?.contractCategory ? (
        <CategoryCard
          contractCategory={contract?.contractCategory}
          explanation={contract?.procurement?.explanation}
        />
      ) : (
        <Typography variant="body1">
          {contract.procurement?.category}
          <br />
          {contract.procurement?.explanation}
        </Typography>
      )}

      {aiOutputs?.length > 0 && (
        <Box my={5}>
          <SimpleTreeView
            defaultExpandedItems={
              aiOutputs?.length > 0
                ? [`${aiOutputs[0]?.id}-contractCategory`]
                : undefined
            }
          >
            {aiOutputs?.map((aiOutput) => {
              // if (aiOutput.id === latestAiOutput?.id) {
              //   // skip the latest ai output as it is already shown
              //   return null;
              // }

              const contractCategory =
                aiOutput.metadata?.structuralData?.contractCategory;

              if (!contractCategory) {
                return null;
              }

              const verificationRecord = verifications?.find(
                (v) =>
                  v.field === "contractCategory" && v.aiOutputId === aiOutput.id
              );

              const document = documents.find(
                (d) => d.id === aiOutput.documentId
              );

              return (
                <TreeItem2
                  itemId={`${aiOutput.id}-contractCategory`}
                  label={
                    <Typography>
                      {aiOutput.processedAt
                        ? t("date.dateTime", {
                            date: new Date(aiOutput.processedAt),
                          })
                        : "No processing date"}
                    </Typography>
                  }
                  style={{
                    marginBottom: 5,
                    gap: 5,
                  }}
                  slotProps={{
                    content: {
                      style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                      },
                    },
                  }}
                >
                  <Box my={2} display="flex" flexDirection="column" gap={2}>
                    {document && documents?.length > 1 && (
                      <div>
                        <Chip
                          label={
                            document.type === "attachment"
                              ? document.name
                              : t("Main document")
                          }
                          color="primary"
                          size="small"
                          // icon={<AttachFile />}
                          variant="outlined"
                        />
                      </div>
                    )}

                    {verificationRecord && (
                      <VerifiedAISuggestion
                        verificationRecord={verificationRecord}
                      />
                    )}

                    <AISuggestion
                      contract={contract}
                      mode={mode}
                      isAiSuggestion={!verificationRecord}
                      collapsible={false}
                      isVerified={verificationRecord?.isCorrect !== undefined}
                      field="contractCategory"
                      value={contractCategory}
                      aiInputId={aiOutput.aiInputId}
                      aiOutputId={aiOutput.id}
                      content={
                        <CategoryCard
                          contractCategory={contractCategory}
                          explanation=""
                        />
                      }
                      processedAt={aiOutput?.processedAt}
                    />
                  </Box>
                </TreeItem2>
              );
            })}
          </SimpleTreeView>
        </Box>
      )}
    </Box>
  );
}

export default ContractCategory;

import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  useAddFolderMutation,
  useDeleteFolderMutation,
  useUpdateFolderMutation,
} from "../../redux/slices/indexApiSlice";
import { Edit as EditIcon, Cancel } from "@mui/icons-material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { Check } from "react-feather";
import useAuth from "../../hooks/useAuth";
import { IFolder } from "../../types/folder";
import FolderForm from "../forms/FolderForm";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

type IFolderDialogProps = {
  mode: DialogMode;
  newAIinfo?: boolean;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  folder?: IFolder;
  initialValues?: any;
};

function FolderDialog(props: IFolderDialogProps) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(props.mode || DialogMode.Add);
  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [
    addFolder,
    { data: addedFolder, isSuccess: isAdded, reset: resetAdd },
  ] = useAddFolderMutation();

  const [
    updateFolder,
    { data: updatedFolder, isSuccess: isUpdated, reset: resetUpdate },
  ] = useUpdateFolderMutation();

  const [
    deleteFolder,
    { isLoading: isDeleting, isSuccess: isDeleted, reset: resetDelete },
  ] = useDeleteFolderMutation();

  const { user } = useAuth();

  const navigate = useNavigate();

  const resetAndClose = () => {
    setOpen(false);

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  useEffect(() => {
    if (isDeleted) {
      // redirect to the folders page
      navigate("/admin/folders", {
        state: { reload: true },
      });

      resetDelete();
    }
  }, [isDeleted]);

  useEffect(() => {
    if (isAdded || isDeleted) {
      // || isUpdated
      setSuccess(true);

      if (isAdded) {
        resetAdd();

        // // log activity
        // const activity: IActivity = {
        //   id: "",
        //   date: new Date().toISOString(),
        //   entity: "contract",
        //   contractId: addedContract.id,
        //   vendorId: addedContract.vendorId,
        //   event: "added",
        //   user: user?.id,
        // };

        // addActivity(activity);
      }

      // if (isUpdated) {
      //   resetUpdate();

      //   // // log activity
      //   // const activity: IActivity = {
      //   //   id: "",
      //   //   date: new Date().toISOString(),
      //   //   entity: "contract",
      //   //   contractId: props.contract ? props.contract.id : "",
      //   //   vendorId: props.contract ? props.contract.vendorId : "",
      //   //   event: "updated",
      //   //   user: user?.id,
      //   // };

      //   // addActivity(activity);
      // }

      if (isDeleted) {
        resetDelete();

        // // log activity
        // const activity: IActivity = {
        //   id: "",
        //   date: new Date().toISOString(),
        //   entity: "contract",
        //   contractId: props.connection ? props.contract.id : "",
        //   vendorId: props.contract ? props.contract.vendorId : "",
        //   event: "deleted",
        //   user: user?.id,
        // };

        // addActivity(activity);
      }
    }
  }, [isAdded, isDeleted]); // isUpdated

  const handleAddFolder = async (folder: IFolder) => {
    // const connection = {
    //   siteId: site,
    //   driveId: drive,
    //   webUrl: sites?.find((s: any) => s.id === site)?.webUrl,
    //   siteDisplayName: sites?.find((s: any) => s.id === site)?.displayName,
    //   driveName: drives?.find((d: any) => d.id === drive)?.name,
    // };

    await addFolder(folder);

    setSuccess(true);

    // close the dialog
    resetAndClose();
  };

  const handleUpdateFolder = async (folder: IFolder) => {
    await updateFolder(folder);

    setSuccess(true);
    // close the dialog
    // setOpen(false);
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    if (props.folder?.id) {
      await deleteFolder(props.folder.id);
    }

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {!showConfirmation && (
            <Tooltip title={t("Delete")}>
              {props.iconOnly ? (
                <IconButton size="small" onClick={handleDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Button color="primary" onClick={() => setOpen(true)}>
                  {t("Delete connection")}
                </Button>
              )}
            </Tooltip>
          )}

          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">{t("Are you sure?")}</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}
        </>
      )}

      {mode === DialogMode.Add && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          {t("New folder")}
        </Button>
      )}

      {mode === DialogMode.Edit &&
        (props.iconOnly ? (
          <IconButton size="small" onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
        ) : (
          <Button color="primary" onClick={handleEditClick}>
            {t("Edit folder")}
          </Button>
        ))}

      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {mode === DialogMode.Add
            ? t("New folder")
            : mode === DialogMode.Edit
            ? `${t("Edit")} ${props.folder?.name}`
            : mode === DialogMode.Delete
            ? t("Are you sure you want to delete this folder?")
            : ""}
        </DialogTitle>
        {mode !== DialogMode.Delete && (
          <DialogContent>
            {/* <DialogContentText>{mode === "add" ? "Add a new contract." : "Edit the contract"}</DialogContentText> */}

            <FolderForm
              mode={mode}
              addFolder={handleAddFolder}
              updateFolder={handleUpdateFolder}
            />
            {/* <ContractForm
              mode={mode}
              addContract={handleAddContract}
              updateContract={handleUpdateContract}
              contract={props.contract}
              initialValues={props.initialValues}
            /> */}
          </DialogContent>
        )}
        <DialogActions>
          {success ? (
            <Button onClick={() => resetAndClose()} color="primary">
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button
                  onClick={() => resetAndClose()}
                  color="primary"
                  autoFocus
                >
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button type="submit" form="folder-form" color="primary">
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <Button onClick={handleConfirmDelete} color="primary">
                  {t("Delete")}
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FolderDialog;

import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Alert as MuiAlert,
  Button as MuiButton,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  TextField as MuiTextField,
  Typography,
  Card as MuiCard,
  Divider as MuiDivider,
  Tabs,
  Tab,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import { DialogMode } from "../../../types/dialogmode";
import { useTranslation } from "react-i18next";
import AskMeAnything from "../../components/AskMeAnything";
import DataGridPrompts from "../../../components/datagrids/DataGridPrompts";
import PromptDialog from "../../../components/dialogs/PromptDialog";
import { useGetContractCategoriesQuery } from "../../../redux/slices/indexApiSlice";
const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function PromptsList({ ...props }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: contractCategories } = useGetContractCategoriesQuery();

  const [selectedContractCategory, setSelectedContractCategory] =
    React.useState<string>();

  useEffect(() => {
    if (selectedContractCategory) {
      navigate(`/libraries/prompts/${selectedContractCategory}`);
    } else {
      navigate("/libraries/prompts/all");
    }
  }, [selectedContractCategory]);

  return (
    <React.Fragment>
      <Helmet title={t("Prompts")!} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/libraries/prompts" color="inherit">
              <Typography variant="h3" gutterBottom display="inline">
                {t("Prompts")}
              </Typography>
            </Link>
          </Breadcrumbs>
        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>
          <AskMeAnything placeholder={"Ask a question about prompting"} />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container direction="column" gap={6}>
        <Grid>
          <Grid container gap={3}>
            <Grid>
              <PromptDialog mode={DialogMode.Add} />
            </Grid>
          </Grid>
        </Grid>

        <Grid>
          <Paper>
            <Tabs
              value={selectedContractCategory}
              variant="scrollable"
              textColor="primary"
              indicatorColor="primary"
              onChange={(_, newValue) => {
                setSelectedContractCategory(newValue);
              }}
            >
              <Tab label={t("All")} value={undefined} />

              {contractCategories?.map((contractCategory) => (
                <Tab
                  key={contractCategory.id}
                  label={contractCategory.title}
                  value={contractCategory.id}
                />
              ))}
            </Tabs>
            <DataGridPrompts view="table" />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default PromptsList;

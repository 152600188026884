import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  useAddContractMutation,
  useDeleteContractMutation,
  useUpdateContractMutation,
} from "../../redux/slices/indexApiSlice";
import { Edit as EditIcon, Cancel } from "@mui/icons-material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { IContract } from "../../types/contract";

import { useTranslation } from "react-i18next";
import { DialogMode } from "../../types/dialogmode";
import { Check } from "react-feather";
import useAuth from "../../hooks/useAuth";
import ContractForm from "../forms/ContractForm";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);

type IContractDialogProps = {
  mode: DialogMode;
  iconOnly?: boolean;
  inlineConfirmation?: boolean;
  contract: IContract;
};

function ContractDialog(props: IContractDialogProps) {
  const { t } = useTranslation();

  const { contract } = props;

  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(props.mode || DialogMode.Add);
  const [success, setSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formValues, setFormValues] = useState<IContract>(contract);

  const [
    addContract,
    { data: addedContract, isSuccess: isAdded, reset: resetAdd },
  ] = useAddContractMutation();
  const [
    updateContract,
    { data: updatedContract, isSuccess: isUpdated, reset: resetUpdate },
  ] = useUpdateContractMutation();
  const [
    deleteContract,
    {
      data: deletedContract,
      isLoading: isDeleting,
      isSuccess: isDeleted,
      reset: resetDelete,
    },
  ] = useDeleteContractMutation();

  const { user } = useAuth();

  const navigate = useNavigate();

  const resetAndClose = () => {
    setOpen(false);

    // wait for the dialog to close
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  useEffect(() => {
    if (contract) {
      setFormValues(contract);
    }
  }, [contract]);

  useEffect(() => {
    if (isDeleted) {
      // redirect to the contracts page
      navigate("/contracts", {
        state: { reload: true },
      });

      resetDelete();
    }
  }, [isDeleted]);

  useEffect(() => {
    if (isAdded || isUpdated || isDeleted) {
      if (isAdded && addedContract) {
        resetAdd();
      }

      if (isUpdated && updatedContract) {
        resetUpdate();

        resetAndClose();
      }

      if (isDeleted && deletedContract) {
        resetDelete();
      }

      setSuccess(true);

      // close the dialog
      resetAndClose();
    }
  }, [
    isAdded,
    isUpdated,
    isDeleted,
    addedContract,
    updatedContract,
    deletedContract,
  ]);

  const handleAddContract = async (contract: IContract) => {
    await addContract(contract);

    // close the dialog
    resetAndClose();
  };

  const handleSave = async () => {
    if (contract?.id === formValues?.id) {
      await updateContract(formValues);
    }
  };

  const handleConfirmDelete = async (e: any) => {
    e.stopPropagation();

    // Call the delete function to delete the item
    await deleteContract(props.contract.id);

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleCancelDelete = (e: any) => {
    e.stopPropagation();

    // Hide the confirmation
    setShowConfirmation(false);
  };

  const handleEditClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleDeleteClick = (e: any) => {
    e.stopPropagation();

    if (props.inlineConfirmation) {
      setShowConfirmation(true);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      {mode === DialogMode.Delete && (
        <>
          {!showConfirmation && (
            <Tooltip title={t("Delete contract")}>
              {props.iconOnly ? (
                <IconButton size="small" onClick={handleDeleteClick}>
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Button color="primary" onClick={() => setOpen(true)}>
                  {t("Delete contract")}
                </Button>
              )}
            </Tooltip>
          )}

          {props.inlineConfirmation && showConfirmation && (
            <Fade in={showConfirmation}>
              <Box>
                <Typography component="span">{t("Are you sure?")}</Typography>
                <IconButton
                  aria-label="Cancel"
                  size="small"
                  onClick={handleCancelDelete}
                >
                  <Cancel />
                </IconButton>
                <IconButton
                  aria-label="Confirm"
                  size="small"
                  onClick={handleConfirmDelete}
                >
                  <Check />
                </IconButton>
              </Box>
            </Fade>
          )}
        </>
      )}

      {mode === DialogMode.Add && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          {t("New contract")}
        </Button>
      )}

      {mode === DialogMode.Edit && (
        <Tooltip title={t("Edit contract")}>
          {props.iconOnly ? (
            <IconButton size="small" onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          ) : (
            <Button color="primary" onClick={handleEditClick}>
              {t("Quick edit")}
            </Button>
          )}
        </Tooltip>
      )}

      <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {mode === DialogMode.Add
            ? t("New contract")
            : mode === DialogMode.Edit
            ? `${t("Edit")} ${props.contract?.name}`
            : mode === DialogMode.Delete
            ? t("Are you sure you want to delete this contract?")
            : ""}
        </DialogTitle>
        {mode !== DialogMode.Delete && (
          <DialogContent>
            {/* <DialogContentText>{mode === "add" ? "Add a new contract." : "Edit the contract"}</DialogContentText> */}

            {/* <ContractForm
              mode={mode}
              addContract={handleAddContract}
              updateContract={handleUpdateContract}
              contract={props.contract}
              initialValues={props.initialValues}
            /> */}

            <ContractForm
              initialValues={formValues}
              setFormValues={setFormValues}
            />
          </DialogContent>
        )}
        <DialogActions>
          {success ? (
            <Button
              variant="contained"
              onClick={() => resetAndClose()}
              color="primary"
            >
              {t("Close")}
            </Button>
          ) : (
            <>
              {!success && (
                <Button
                  onClick={() => resetAndClose()}
                  color="primary"
                  autoFocus
                >
                  {t("Cancel")}
                </Button>
              )}

              {(mode === DialogMode.Edit || mode === DialogMode.Add) && (
                <Button
                  variant="contained"
                  onClick={handleSave}
                  color="primary"
                >
                  {t("Save")}
                </Button>
              )}

              {mode === DialogMode.Delete && (
                <LoadingButton
                  variant="contained"
                  loading={isDeleting}
                  onClick={handleConfirmDelete}
                  color="primary"
                >
                  {t("Delete")}
                </LoadingButton>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ContractDialog;

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { spacing } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  useAddSettingsMutation,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from "../../../redux/slices/indexApiSlice";

import { ISettings } from "../../../types/settings";

import { DialogMode } from "../../../types/dialogmode";

import SharePointConnectionForm from "../../../components/forms/SharePointConnectionForm";
import { ISharePointConnection } from "../../../types/sharepointconnection";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

// enum for the data location
enum DataLocationOption {
  Default = "default",
  SharePoint = "sharepoint",
  AzureBlobStorage = "azureblobstorage",
}

function DataLocation() {
  const { t } = useTranslation();

  const [selectedDataLocation, setSelectedDataLocation] = useState<string>(
    DataLocationOption.Default
  );
  const [selectedSharePointConnection, setSelectedSharePointConnection] =
    useState<ISharePointConnection>();

  const {
    data: settings,
    isLoading: settingsLoading,
    isSuccess: settingsLoaded,
    refetch,
    isError: isSettingsError,
    error: settingsError,
  } = useGetSettingsQuery();

  const [addSettings, { isLoading: isAdding, isSuccess: isAdded }] =
    useAddSettingsMutation();

  const [updateSettings, { isLoading: isUpdating, isSuccess: isUpdated }] =
    useUpdateSettingsMutation();

  useEffect(() => {
    if (isUpdated || isAdded) {
      refetch();
    }
  }, [isUpdated, isAdded]);

  useEffect(() => {
    if (settingsLoaded) {
      setSelectedDataLocation(settings?.dataLocation?.source);

      if (settings?.dataLocation?.source === "sharepoint") {
        setSelectedSharePointConnection(settings?.dataLocation?.connection);
      }
    }
  }, [settingsLoaded, settings]);

  const saveDataLocationSharePoint = async (
    connection: ISharePointConnection
  ) => {
    // store the selected location with the settings
    const updatedSettings: ISettings = {
      ...settings,
      defaultLanguage: settings?.defaultLanguage,
      notificationPeriod: settings?.notificationPeriod,
      dataLocation: {
        source: selectedDataLocation,
        connection: connection,
      },
    };

    if (settings?.id) {
      await updateSettings(updatedSettings);
    } else {
      await addSettings(updatedSettings);
    }
    // const connection = {
    //   siteId: site,
    //   driveId: drive,
    //   webUrl: sites?.find((s: any) => s.id === site)?.webUrl,
    //   siteDisplayName: sites?.find((s: any) => s.id === site)?.displayName,
    //   driveName: drives?.find((d: any) => d.id === drive)?.name,
    // };
    // await addConnection(connection);
    // setSuccess(true);
    // // close the dialog
    // resetAndClose();
  };

  return (
    <React.Fragment>
      <Helmet title={t("Trusted Partner")!} />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid>
          <Typography variant="h3" gutterBottom display="inline">
            {t("Data Location")}
          </Typography>

          {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/admin">
              {t("Admin")}
            </Link>
            <Typography>{t("Data Location")}</Typography>
          </Breadcrumbs> */}
        </Grid>
      </Grid>

      <Divider my={6} />

      {settingsLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={6} direction="column">
          <Grid>
            {settingsLoaded && (
              <FormControl>
                <RadioGroup
                  defaultValue={
                    settings?.dataLocation?.source ?? DataLocationOption.Default
                  }
                  name="dataLocation"
                  onChange={(event, value: string) =>
                    setSelectedDataLocation(value)
                  }
                >
                  <FormControlLabel
                    value={DataLocationOption.Default}
                    control={<Radio />}
                    label={t("Default")}
                  />
                  <Typography variant="body2" gutterBottom>
                    Contract documents are stored in the GoBeyond Blob Storage.
                    Documents can be uploaded or imported from various M365
                    sources. Uploads and imports are then stored in the GoBeyond
                    Blob Storage. This option provides full capabilities
                    including Digital Signatures.
                  </Typography>

                  <FormControlLabel
                    value={DataLocationOption.SharePoint}
                    control={<Radio />}
                    label={t("SharePoint")}
                  />
                  <Typography variant="body2" gutterBottom>
                    Contract documents are stored in a SharePoint Document
                    Library. Documents can still be uploaded or imported in the
                    web app, and they will be saved in the designated SharePoint
                    location. Digital Signatures will not be available.
                  </Typography>

                  <FormControlLabel
                    value={DataLocationOption.AzureBlobStorage}
                    control={<Radio />}
                    label={t("Azure Blob Storage")}
                  />
                  <Typography variant="body2" gutterBottom>
                    With this option contract documents are stored in a
                    designated Storage Account. This can be on another tenant.
                    Provides full capabilities.
                  </Typography>
                </RadioGroup>
              </FormControl>
            )}
          </Grid>

          <Grid>
            <Typography variant="h6" gutterBottom>
              Your Choice: {selectedDataLocation}
            </Typography>
          </Grid>

          {selectedDataLocation === DataLocationOption.SharePoint && (
            <Grid>
              <SharePointConnectionForm
                mode={DialogMode.Add}
                addConnection={saveDataLocationSharePoint}
                updateConnection={setSelectedSharePointConnection}
                connection={selectedSharePointConnection}
              />

              <Button
                type="submit"
                form="sharepoint-connection-form"
                color="primary"
                variant="contained"
              >
                {t("Save")}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default DataLocation;
